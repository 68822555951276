import React, { useEffect } from "react";
import "./App.css";
import Divider from "./components/Divider";
import Education from "./components/Education";
import Links from "./components/Links";
import Projects from "./components/Projects";
import Works from "./components/Works";
import ram_photo from "./ram.jpg";

function App() {
  useEffect(() => {
    document.title = "Ram";
  });

  return (
    <React.StrictMode>
      <div style={{ display: "block", padding: 30 }} className="App">
        <div
          className="ProfileImage"
          style={{ display: "flex", alignItems: "center" }}
        >
          <img
            src={ram_photo}
            style={{
              width: "100px",
              height: "100px",
              borderRadius: "50%",
              marginRight: "20px",
            }}
            alt="Profile"
          />
          <div>
            <h1
              style={{
                fontWeight: "500",
                fontSize: "16px",
                textAlign: "left",
                margin: "0",
                font: "headline",
              }}
            >
              Ram Prasad Reddy Gajjala
            </h1>
            <h2
              style={{
                fontWeight: "500",
                fontSize: "16px",
                textAlign: "left",
                color: "gray",
                margin: "0",
                paddingTop: "10px",
                font: "subheadline",
              }}
            >
              Make things that make things happen
            </h2>
          </div>
        </div>
        <Divider />

        <div className="Work">
          <Works />
        </div>
        <Divider />
        <div className="Work">
          <Education />
        </div>
        <Projects />

        <Divider />

        <Links />
      </div>
    </React.StrictMode>
  );
}

export default App;
