import React from "react";
import Divider from "./Divider";
function Projects() {
  const ProjectItem = ({ title, url }) => {
    console.log(title);
    return (
      <text
        style={{
          fontWeight: "450",
          fontSize: "16px",
          color: "black",
          paddingTop: "4px",
        }}
      >
        <a
          style={{
            textDecoration: "none",
            color: "inherit",
            fontWeight: "450", // This ensures the link inherits the color from the parent <p>
          }}
          onMouseOver={(e) =>
            (e.currentTarget.style.textDecoration = "underline")
          }
          onMouseOut={(e) => (e.currentTarget.style.textDecoration = "none")}
          href={url}
        >
          {title}
        </a>
      </text>
    );
  };

  return (
    <React.StrictMode>
      <Divider />
      <div
        className="Projects"
        style={{
          display: "flex",
          paddingLeft: "20px",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <h1
          style={{
            fontWeight: "500",
            fontSize: "16px",
            textAlign: "left",
          }}
        >
          Projects
        </h1>
        <div style={{ paddingTop: "4px" }} />
        <ProjectItem
          title="Philia"
          url="https://devpost.com/software/philia-4xy8h9"
        />
        <ProjectItem
          title="i2we"
          url="https://play.google.com/store/apps/details?id=com.ride.socialcarpooling&hl=en"
        />
        <ProjectItem
          title="Reeliv"
          url="https://play.google.com/store/apps/details?id=com.reeliv.media&hl=en"
        />
      </div>
    </React.StrictMode>
  );
}

export default Projects;
