import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import React from "react";

function Links() {
  return (
    <React.StrictMode>
      <div className="Links">
        <h1
          style={{
            fontWeight: "500",
            fontSize: "16px",
            textAlign: "left",
            paddingLeft: "20px",
          }}
        >
          Links
        </h1>
        <p
          style={{
            fontWeight: "300",
            fontSize: "16px",
            textAlign: "left",
            paddingLeft: "20px",
            paddingTop: "10px",
            color: "black",
          }}
        >
          <a
            style={{
              textDecoration: "none",
              color: "inherit",
              fontWeight: "450", // This ensures the link inherits the color from the parent <p>
            }}
            onMouseOver={(e) =>
              (e.currentTarget.style.textDecoration = "underline")
            }
            onMouseOut={(e) => (e.currentTarget.style.textDecoration = "none")}
            href="https://linkedin.com/in/ramprg"
          >
            <FontAwesomeIcon
              style={{
                color: "gray",
                marginRight: "10px",
                alignSelf: "center",
              }} // Added margin to space icon and text
              icon={faLinkedin}
            />
            LinkedIn
          </a>
        </p>
        <p
          style={{
            fontWeight: "300",
            fontSize: "16px",
            textAlign: "left",
            paddingLeft: "20px",
            paddingTop: "5px",
            color: "black",
          }}
        >
          <a
            style={{
              textDecoration: "none",
              color: "inherit",
              fontWeight: "450", // This ensures the link inherits the color from the parent <p>
            }}
            onMouseOver={(e) =>
              (e.currentTarget.style.textDecoration = "underline")
            }
            onMouseOut={(e) => (e.currentTarget.style.textDecoration = "none")}
            href="https://github.com/gramprasadreddy"
          >
            <FontAwesomeIcon
              style={{
                color: "gray",
                marginRight: "10px",
                alignSelf: "center",
              }} // Added margin to space icon and text
              icon={faGithub}
            />
            Github
          </a>
        </p>
        <p
          style={{
            fontWeight: "300",
            fontSize: "16px",
            textAlign: "left",
            paddingLeft: "20px",
            paddingTop: "5px",
            display: "flex",
            alignItems: "center",
            height: "100%",
          }}
        >
          <a
            href="mailto:gajjalaramprasadreddy@gmail.com"
            style={{
              textDecoration: "none",
              color: "inherit",
              alignItems: "center",
              fontWeight: "450", // Ensures the link inherits the color from the parent <p>
            }}
            onMouseOver={(e) =>
              (e.currentTarget.style.textDecoration = "underline")
            }
            onMouseOut={(e) => (e.currentTarget.style.textDecoration = "none")}
          >
            <FontAwesomeIcon
              style={{
                color: "gray",
                marginRight: "10px",
                alignSelf: "center",
              }} // Added margin to space icon and text
              icon={faEnvelope}
            />
            gajjalaramprasadreddy@gmail.com
          </a>
        </p>
      </div>
    </React.StrictMode>
  );
}
export default Links;
