import { faUniversity } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import vitlogo from "../icons/vitlog.svg";
function Works() {
  return (
    <React.StrictMode>
      <h1
        style={{
          fontWeight: "500",
          fontSize: "16px",
          textAlign: "left",
          paddingLeft: "20px",
        }}
      >
        {" "}
        Education
      </h1>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          textAlign: "left",
          paddingTop: "4px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <FontAwesomeIcon
            icon={faUniversity}
            style={{
              paddingLeft: "24px",
              paddingRight: "10px",
            }}
          />
          <text
            style={{
              fontWeight: "450",
              fontSize: "16px",
              color: "black",
              paddingTop: "4px",
              paddingLeft: "8px",
            }}
          >
            Master of Science, Computer Science, University of Texas at
            Arlington (May 2025)
          </text>
        </div>

        <div
          style={{ display: "flex", alignItems: "center", paddingLeft: "20px" }}
        >
          <img
            src={vitlogo}
            style={{
              width: "24px",
              height: "24px",
              borderRadius: "50%",
              paddingTop: "4px",
            }}
            alt="philips"
          />
          <text
            style={{
              fontWeight: "450",
              fontSize: "16px",
              color: "black",
              paddingLeft: "14px",
              paddingTop: "4px",
            }}
          >
            Master of Technology, Software Engineering, Vellore Institute of
            Technology (2020)
          </text>
        </div>
      </div>
    </React.StrictMode>
  );
}
export default Works;
