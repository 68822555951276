import { faUniversity } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import philipslogo from "../icons/philips.png";
function Works() {
  return (
    <React.StrictMode>
      <h1
        style={{
          fontWeight: "500",
          fontSize: "16px",
          textAlign: "left",
          paddingLeft: "20px",
        }}
      >
        {" "}
        Work
      </h1>
      <h2
        style={{
          fontWeight: "500",
          fontSize: "16px",
          textAlign: "left",
          paddingLeft: "20px",
          color: "gray",
          paddingTop: "8px",
        }}
      >
        Research, Software Development Engineer
      </h2>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          textAlign: "left",
          paddingTop: "4px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <FontAwesomeIcon
            icon={faUniversity}
            style={{
              paddingLeft: "20px",
              paddingRight: "10px",
            }}
          />
          <text
            style={{
              fontWeight: "450",
              fontSize: "16px",
              color: "black",
              paddingTop: "4px",
            }}
          >
            Research Assistant, UTA (2023 - Present)
          </text>
        </div>

        <div
          style={{ display: "flex", alignItems: "center", paddingLeft: "20px" }}
        >
          <img
            src={philipslogo}
            style={{
              width: "18px",
              height: "18px",
              borderRadius: "50%",
              background: "black",
              paddingTop: "2px",
            }}
            alt="philips"
          />
          <text
            style={{
              fontWeight: "450",
              fontSize: "16px",
              color: "black",
              paddingLeft: "8px",
              paddingTop: "4px",
            }}
          >
            SDE 2,{" "}
            <a
              href="https://www.philips.com"
              style={{ color: "black", textDecoration: "none" }}
              onMouseOver={(e) =>
                (e.currentTarget.style.textDecoration = "underline")
              }
              onMouseOut={(e) =>
                (e.currentTarget.style.textDecoration = "none")
              }
            >
              Philips
            </a>{" "}
            (2019 - 2023)
          </text>
        </div>
      </div>
    </React.StrictMode>
  );
}
export default Works;
