import React from "react";

const Divider = () => {
  return (
    <div
      style={{
        width: "100%",
        borderTop: "0.2px solid #d3d3d3",
        margin: "20px 0",
      }}
    ></div>
  );
};

export default Divider;
